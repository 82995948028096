import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { chartColors } from 'Theme';

class DoughnutChart extends Component {

  render() {

    let dataSetTotal = 0;

    try {
      const reducer = (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue);
      dataSetTotal = this.props.dataSets.reduce(reducer);
    } catch(err) {}

    return(
      <Pie 
        height={this.props.height}
        data={{
          datasets: [
            {
              data: this.props.dataSets,
              backgroundColor: [
                chartColors.red,
                chartColors.yellow,
                chartColors.blue,
                chartColors.orange,
                chartColors.green,
                chartColors.purple,
                chartColors.grey
              ]
            }
          ],
          labels: this.props.labels
        }}
        options={{
          responsive: true,
          cutoutPercentage: 60,
          legend: {
            labels: {
              boxWidth: 12,
            }
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                let label = data.labels[tooltipItem.index] || '';
                if (label) {
                  label += ': ';
                }
                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                label += Math.round(value * 10) / 10 + ' ' + this.props.unit;
                return label;
              }
            }
          }, 
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.forEach(data => {
                    sum += Number(data);
                });
                let percentage = value ? (value*100 / sum).toFixed(1)+"%" : '';
                return percentage;
              },
              color: '#36A2EB',
              font: {
                weight: 'bold',
              },
              anchor: 'end',
              align: 'end',
              display: 'auto'
            }
          },
          elements: {
            center: {
              showText: this.props.showDatasetTotal ? true : false,
              units: this.props.unit,
              text: dataSetTotal,
              color: '#666', // Default is #000000
              fontStyle: 'Lato', // Default is Arial
              fontWeight: 'bold', // Default is Arial
              sidePadding: 30 // Default is 20 (as a percentage)
            }
          }
        }}
        plugins={ chartPlugins }
      />
    )
  }
  
}

const chartPlugins = [
  {
    id: 'doughnut-center-text',
    beforeDraw: function (chart) {
      if (chart.config.options.elements.center) {
        //Get ctx from string
        var ctx = chart.chart.ctx;
        
        //Get options from the center object in options
        var centerConfig = chart.config.options.elements.center;
        var fontStyle = centerConfig.fontStyle || 'Arial';
        var fontWeight = centerConfig.fontWeight || '';
        var txt = centerConfig.text;
        var color = centerConfig.color || '#000';
        var sidePadding = centerConfig.sidePadding || 20;
        var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
        //Start with a base font of 30px
        ctx.font = "30px " + fontStyle;
        
        //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
        var stringWidth = ctx.measureText(txt).width;
        var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        var widthRatio = elementWidth / stringWidth;
        var newFontSize = Math.floor(30 * widthRatio);
        var elementHeight = (chart.innerRadius * 2);

        // Pick a new font size so it will not be larger than the height of label.
        var fontSizeToUse = Math.min(newFontSize, elementHeight);

        //Set font settings to draw it correctly.
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
        ctx.font = fontWeight + " " + fontSizeToUse + "px " + fontStyle;
        ctx.fillStyle = color;
        
        //Draw text in center
        ctx.fillText(txt, centerX, centerY);
      }
    }
  },
  {
    id: 'dataset-total-updater',
    afterUpdate: function(chart) {
      const total = chart.getDatasetMeta(0).total;
      const units = chart.config.options.elements.center.units ? (' ' + chart.config.options.elements.center.units) : '';
      if (chart.config.options.elements.center.showText) {
        chart.config.options.elements.center.text = Math.round(total * 10) / 10 + units;
      } else {
        chart.config.options.elements.center.text = '';
      }
    }
  },
  ChartDataLabels,
  {
    adjustChartLayout: function(chart) {
      const size = { width: chart.width, height: chart.height }
      if (size.width >= 555) {
        chart.legend.options.position = 'right';
        chart.legend.options.labels.padding = 15;
        chart.options.layout.padding = {
          left: 0,
          right: 40,
          top: 22,
          bottom: 22
        };
      }
      if (size.width > 495 && size.width < 555) {
        chart.legend.options.position = 'right';
        chart.options.layout.padding = {
          left: 0,
          right: 20,
          top: 22,
          bottom: 22
        };
        chart.legend.options.labels.padding = 15;
      }
      if (size.width > 480 && size.width <= 495) {
        chart.legend.options.position = 'right';
        chart.options.layout.padding = {
          left: 0,
          right: 15,
          top: 22,
          bottom: 22
        };
        chart.legend.options.labels.padding = 14;
      }
      if (size.width <= 480) {
        chart.legend.options.position = 'bottom';
        chart.options.layout.padding = {
          left: 0,
          right: 0,
          top: 22,
          bottom: 0
        };
        chart.legend.options.labels.padding = 12;
      }

    },
    beforeLayout: function(chart) {
      this.adjustChartLayout(chart)
    },
    resize: function(chart) {
      this.adjustChartLayout(chart)
    }
  }
]

export default DoughnutChart;