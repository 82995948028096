import React, { Fragment, Component } from 'react';
import styled from 'styled-components';

import { Auth } from 'aws-amplify';

import { Icon, Loader, Message } from 'semantic-ui-react';

import { connect } from 'react-redux';

import MainContentWrapper from 'components/commons/MainContentWrapper';

import HesaApiSettings from 'views/SettingsView/HesaApiSettings';
import TimezoneSettings from 'views/SettingsView/TimezoneSettings';

import { themeColors } from 'Theme';

const { 
  tileBackgroundColor,
  primaryColor
} = themeColors;


class SettingsView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hesa_api_settings: {
        hesa_api_client_id: '',
        hesa_api_client_secret: ''
      },
      timezone_settings: {
        user_timezone: 'america/toronto'
      },
      is_loading: true
    }
    this.loadSettings();
  }

  loadSettings = async () => {
    // Retrieving User settings
    let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const { attributes } = user;
    // Updating the state with the page settings
    this.setState({
      hesa_api_settings: {
        hesa_api_client_id: attributes["custom:hesa_api_client_id"],
        hesa_api_client_secret: attributes["custom:hesa_api_secret"]
      },
      timezone_settings: {
        user_timezone: attributes["custom:user_timezone"]
      },
      is_loading: false
    });
  }

  render() {
    const loader = (
      <Loader active inline='centered' style={{top: '30%'}}>Loading Settings...</Loader>
    )
    const content = (
      <Fragment>
        {
          this.props.hesa.access_token === null ? (
          <Message 
            color='yellow' 
            floating
            icon='exclamation triangle'
            header='Verify the settings highlighted in RED below'
            content='If it is the first time that you are logging in, please take the time to check all of your settings'
          />) : null
        }
        <h2><Icon name='chevron right'/>App Settings</h2>
        <FormContainer backgroundColor={tileBackgroundColor}>
          <HesaApiSettings settings={this.state.hesa_api_settings}/>
        </FormContainer>
        <FormContainer backgroundColor={tileBackgroundColor}>
          <TimezoneSettings settings={this.state.timezone_settings}/>
        </FormContainer>
      </Fragment>
    )
    return(
      <MainContentWrapper>
        {this.state.is_loading ? loader : content }
      </MainContentWrapper>
    )
  }

}

const FormContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  /* max-width: 500px; */
  border-radius: 4px;
  border-left: 4px solid ${primaryColor};
  background-color: ${props => props.backgroundColor};
  box-shadow: 1px 1px 7px -1px rgba(0,0,0,0.28);
`

const mapStateToProps = (state) => ({
  hesa: state.hesa
});

export default connect(mapStateToProps)(SettingsView);