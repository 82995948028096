export const themeColors = {
  primaryColor: '#00b5ad',
  secondaryColor: 'rgb(54, 162, 235)',
  tertiaryColor: '#f76397',
  bodyBackgroundColor: '#fbfbfb',
  labelTextColor: 'rgb(96, 117, 139)',
  bodyTextColor: '#003b61',
  navBackgroundColor: '#2d353c',
  navTextColor: '#a8acb1',
  navActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.4)',
  navActiveLinkTextColor: '#ffffff',
  tileBackgroundColor: '#fff',
  tileTextColor: '#98a6ad',
}


export const chartColors = {
  red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
	purple: 'rgb(153, 102, 255)',
	grey: 'rgb(201, 203, 207)'
}