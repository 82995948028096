import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DateTime } from 'luxon';

import { Icon } from 'semantic-ui-react';

import Papa from 'papaparse';

import MainContentWrapper from 'components/commons/MainContentWrapper';
import ChartTile from 'components/commons/ChartTile';
import ChartStats from 'components/commons/ChartStats';
import H26NettingViewActionBar from 'views/H26NettingView/H26NettingViewActionBar';
import TimeseriesBarChart from 'components/charts/TimeseriesBarChart';

import Timezones from 'Timezones';
import Communities from 'Communities';
import TimeOfUseSchedules from 'TimeOfUseSchedules';

import {
  fetchHesaCommunityData,
  fetchHesaHomeData
} from 'api/HesaApi';

import { themeColors } from 'Theme';

import WebWorker from 'views/H26NettingView/H26NettingView.worker';


const TimeGranularities = {
  1: {  
    key: 'g1',
    text: '1 minute',
    value: 1
  },
  2: {  
    key: 'g2',
    text: '2 minutes',
    value: 2
  },
  3: {  
    key: 'g3',
    text: '5 minutes',
    value: 5
  },
  4: {  
    key: 'g4',
    text: '10 minutes',
    value: 10
  },
  5: {  
    key: 'g5',
    text: '15 minutes',
    value: 15
  },
  6: {  
    key: 'g6',
    text: '30 minutes',
    value: 30
  },
  // 7: {  
  //   key: 'g7',
  //   text: '60 minutes',
  //   value: 60
  // },
  // 8: {  
  //   key: 'g8',
  //   text: '120 minutes',
  //   value: 120
  // }
}


const {
  primaryColor
} = themeColors;

const defaultTimezone = 'america_toronto';

class H26NettingView extends Component {

  constructor(props) {
    super(props);

    this.H26NettingViewWorker = new WebWorker();
    this.H26NettingViewWorker.onmessage = ev => this.handleMessageFromWorker(ev);

    this.communityTimezone = Communities[1].timezone;
    this.userTimezone = this.props.user['custom:user_timezone'] || defaultTimezone;

    this.isoTimezone = Timezones[this.userTimezone].text;
    this.numberFormat = Timezones[this.userTimezone].number_local;

    this.state = {

      timeGranularity: 5,

      communityImportDatasetsForChart: {data: [], meta: null},
      communityExportDatasetsForChart: {data: [], meta: null},

      villa1ImportDatasetsForChart: {data: [], meta: null},
      villa1ExportDatasetsForChart: {data: [], meta: null},

      villa2ImportDatasetsForChart: {data: [], meta: null},
      villa2ExportDatasetsForChart: {data: [], meta: null},

      villa3ImportDatasetsForChart: {data: [], meta: null},
      villa3ExportDatasetsForChart: {data: [], meta: null},

      villa4ImportDatasetsForChart: {data: [], meta: null},
      villa4ExportDatasetsForChart: {data: [], meta: null},

      villa5ImportDatasetsForChart: {data: [], meta: null},
      villa5ExportDatasetsForChart: {data: [], meta: null},

      villa6ImportDatasetsForChart: {data: [], meta: null},
      villa6ExportDatasetsForChart: {data: [], meta: null},

      totalInjectedByHomesAndUtilityDatasetsForChart: {data: [], meta: null},
      totalExtractedByHomesAndUtilityDatasetsForChart: {data: [], meta: null},

      commonElementsConsDatasetsForChart: {data: [], meta: null},

      meteredCommonElementsConsDatasetsForChart: {data: [], meta: null},

      communityNettingData: {data: null, meta: null},

      communityAndVillaDatasetsUpdateCounter: 0,

      timeSpanStart: DateTime.local().setZone(this.isoTimezone).minus({days:1}).startOf('day').toMillis(),
      timeSpanEnd: DateTime.local().setZone(this.isoTimezone).startOf('day').toMillis()
    }

  }

  componentDidMount() {
    this.getDataFromApiForState();
  }

  clearAllChartDatasets() {
    this.setState({
      communityImportDatasetsForChart: {data: [], meta: null},
      communityExportDatasetsForChart: {data: [], meta: null},
      villa1ImportDatasetsForChart: {data: [], meta: null},
      villa1ExportDatasetsForChart: {data: [], meta: null},
      villa2ImportDatasetsForChart: {data: [], meta: null},
      villa2ExportDatasetsForChart: {data: [], meta: null},
      villa3ImportDatasetsForChart: {data: [], meta: null},
      villa3ExportDatasetsForChart: {data: [], meta: null},
      villa4ImportDatasetsForChart: {data: [], meta: null},
      villa4ExportDatasetsForChart: {data: [], meta: null},
      villa5ImportDatasetsForChart: {data: [], meta: null},
      villa5ExportDatasetsForChart: {data: [], meta: null},
      villa6ImportDatasetsForChart: {data: [], meta: null},
      villa6ExportDatasetsForChart: {data: [], meta: null},
      totalInjectedByHomesAndUtilityDatasetsForChart: {data: [], meta: null},
      totalExtractedByHomesAndUtilityDatasetsForChart: {data: [], meta: null},
      commonElementsConsDatasetsForChart: {data: [], meta: null},
      meteredCommonElementsConsDatasetsForChart: {data: [], meta: null},
      communityNettingData: {data: null, meta: null},
      communityAndVillaDatasetsUpdateCounter: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Only go fetch new data if the timespan has changed
    // TODO: Add condition for community change as well
    if (
      this.state.timeSpanStart !== prevState.timeSpanStart || 
      this.state.timeSpanEnd !== prevState.timeSpanEnd ||
      this.state.timeGranularity !== prevState.timeGranularity
    ) {
      this.clearAllChartDatasets();
      this.getDataFromApiForState();
    }

    if (this.state.communityAndVillaDatasetsUpdateCounter === 14 && prevState.communityAndVillaDatasetsUpdateCounter !== 14) {
      this.H26NettingViewWorker.postMessage({
        command: 'getNettingDatasetsForChart',
        ref: 'netting',
        data: {
          communityImport: this.state.communityImportDatasetsForChart.data,
          communityExport: this.state.communityExportDatasetsForChart.data,
          villa1Import: this.state.villa1ImportDatasetsForChart.data,
          villa1Export: this.state.villa1ExportDatasetsForChart.data,
          villa2Import: this.state.villa2ImportDatasetsForChart.data,
          villa2Export: this.state.villa2ExportDatasetsForChart.data,
          villa3Import: this.state.villa3ImportDatasetsForChart.data,
          villa3Export: this.state.villa3ExportDatasetsForChart.data,
          villa4Import: this.state.villa4ImportDatasetsForChart.data,
          villa4Export: this.state.villa4ExportDatasetsForChart.data,
          villa5Import: this.state.villa5ImportDatasetsForChart.data,
          villa5Export: this.state.villa5ExportDatasetsForChart.data,
          villa6Import: this.state.villa6ImportDatasetsForChart.data,
          villa6Export: this.state.villa6ExportDatasetsForChart.data
        },
        tou_schedules: TimeOfUseSchedules,
        timezone: this.isoTimezone,
      })
      console.log("Netting worker message dispatched!");
    }

  }

  componentWillUnmount() {
    // Clear all datasets
    this.clearAllChartDatasets();
    // Kill the web worker before we leave
    this.H26NettingViewWorker.terminate();
  }

  handleMessageFromWorker = (event) => {

    const data = event.data.data || null;
    const meta = event.data.meta || null;

    switch (event.data.ref) {

      // Community
      case 'community_import':
        this.setState({ communityImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'community_export':
        this.setState({ communityExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      // Metered Common Elements Cons
      case 'metered_common_elements_cons':
        this.setState({ meteredCommonElementsConsDatasetsForChart: {data, meta} });
        break;

      // Villa 1
      case 'villa_1_import':
        this.setState({ villa1ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_1_export':
        this.setState({ villa1ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      // Villa 2
      case 'villa_2_import':
        this.setState({ villa2ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_2_export':
        this.setState({ villa2ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      // Villa 3
      case 'villa_3_import':
        this.setState({ villa3ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_3_export':
        this.setState({ villa3ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      // Villa 4
      case 'villa_4_import':
        this.setState({ villa4ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_4_export':
        this.setState({ villa4ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      // Villa 5
      case 'villa_5_import':
        this.setState({ villa5ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_5_export':
        this.setState({ villa5ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      // Villa 6
      case 'villa_6_import':
        this.setState({ villa6ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_6_export':
        this.setState({ villa6ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;

      case 'netting':
        this.setState({ 
          totalInjectedByHomesAndUtilityDatasetsForChart: { data: data.totalInjectedByHomesAndUtility.data, meta: data.totalInjectedByHomesAndUtility.meta },
          totalExtractedByHomesAndUtilityDatasetsForChart: { data: data.totalExtractedByHomesAndUtility.data, meta: data.totalExtractedByHomesAndUtility.meta },
          commonElementsConsDatasetsForChart: { data: data.commonElementsCons.data, meta: data.commonElementsCons.meta },
          communityNettingData: { data: data.communityNettingData.data, meta: data.communityNettingData.meta },
        });
        break;

      default:
        break;
    }
  }

  getDataFromApiForState() {

    const timespanPadding = 15 * 24 * 60 * 60 * 1000;

    const defaultGetDatasetsForChartWorkerData = {
      command: 'getDatasetsForChart',
      ref: '',
      data: [],
      start_time: this.state.timeSpanStart,
      end_time: this.state.timeSpanEnd,
      period: 60000,  // Period of the data used for normalization
      // max_gap_span: 60000 * 60,
      max_gap_span: 2592000000,
      ticks: this.state.timeGranularity,
      multiplier: 1,
      timezone: this.isoTimezone,
      trim_peaks: true,
      trim_peaks_max_delta_value: 1000,
      tou_schedules: TimeOfUseSchedules,
      calculate_tou: true
    };

    /*******************************************
     *    Metered Common Elements Consumption
     *******************************************/
    fetchHesaCommunityData({
      api_access_token: this.props.hesa.access_token,
      community_id: 1,
      address: 'HESA_common_elements_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'metered_common_elements_cons',
        data: res.data
      });
    }).catch(err => console.log(err));

    /*******************************************
     *    Community Utility Import / Export
     *******************************************/
    fetchHesaCommunityData({
      api_access_token: this.props.hesa.access_token,
      community_id: 1,
      address: 'HESA_community_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'community_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaCommunityData({
      api_access_token: this.props.hesa.access_token,
      community_id: 1,
      address: 'HESA_community_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'community_export',
        data: res.data
      });
    }).catch(err => console.log(err));

    /*******************************************
     *    VILLA 1
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 1,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_1_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 1,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_1_export',
        data: res.data
      });
    }).catch(err => console.log(err));

    /*******************************************
     *    VILLA 2
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 2,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_2_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 2,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_2_export',
        data: res.data
      });
    }).catch(err => console.log(err));
    
    /*******************************************
     *    VILLA 3
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 3,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_3_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 3,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_3_export',
        data: res.data
      });
    }).catch(err => console.log(err));

    /*******************************************
     *    VILLA 4
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 4,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_4_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 4,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_4_export',
        data: res.data
      });
    }).catch(err => console.log(err));

    /*******************************************
     *    VILLA 5
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 5,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_5_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 5,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_5_export',
        data: res.data
      });
    }).catch(err => console.log(err));

    /*******************************************
     *    VILLA 6
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 6,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_6_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 6,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.H26NettingViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_6_export',
        data: res.data
      });
    }).catch(err => console.log(err));

  }

  handleFilterChange = (filter) => {

    const timeSpanStart = DateTime.fromMillis(filter.fromDate.valueOf()).setZone(this.isoTimezone).startOf('day').toMillis();
    const timeSpanEnd = DateTime.fromMillis(filter.toDate.valueOf()).setZone(this.isoTimezone).startOf('day').toMillis();

    this.setState({
      timeSpanStart,
      timeSpanEnd,
      timeGranularity: filter.timeGranularity
    })
    
  }

  handleCalculatedCommonElementsConsDownloadButtonClick = () => {
    console.log("Preparing your CSV for download...");

    // Multiply by 1000 because we are dealing with Wh and NOT mWh
    let aggregatedData = this.state.commonElementsConsDatasetsForChart.data.map(e => ({t: e.t, y: e.y * 1000}))
    
    aggregatedData = aggregatedData.slice(aggregatedData.findIndex(e => e.t >= 1583575320000), aggregatedData.findIndex(e => e.t >= 1583921340000));

    // First we figure out the aggregation period of the data:
    let period = aggregatedData[1].t - aggregatedData[0].t;

    // Then we transform the aggregate data into normalized data for ToU calculation
    const energyExportOffset = 28560176;
    let energyExportData = [{ timestamp: aggregatedData[0].t, value: energyExportOffset }];
    const energyImportOffset = 14457333;
    let energyImportData = [{ timestamp: aggregatedData[0].t, value: energyImportOffset }];

    // Push subsequent elements while adding the value for each new element
    aggregatedData.forEach((e, i , arr) => {
      energyExportData.push({
        timestamp: e.t + period,
        // value: (e.y > 0) ? (energyExportData[energyExportData.length - 1].value + e.y) : energyExportData[energyExportData.length - 1].value
        value: (e.y > 0) ? (energyExportData[energyExportData.length - 1].value + e.y) - (Math.floor(Math.random() * 8000) / 1000) : energyExportData[energyExportData.length - 1].value
      })
      energyImportData.push({
        timestamp: e.t + period,
        // value: (e.y < 0) ? (energyImportData[energyImportData.length - 1].value + e.y * -1) : energyImportData[energyImportData.length - 1].value
        value: (e.y < 0) ? (energyImportData[energyImportData.length - 1].value + e.y * -1) + (Math.floor(Math.random() * 11500) / 1000) : energyImportData[energyImportData.length - 1].value
      })
    })

    let combinedDatasets = [];

    energyImportData.forEach((e, i, arr) => combinedDatasets.push({
      timestamp: e.timestamp,
      import: energyImportData[i].value,
      export: energyExportData[i].value
    }));

    console.log("aggregatedData :", aggregatedData);
    console.log("energyImportData :", energyImportData);
    console.log("combinedDatasets :", combinedDatasets);

    console.log("Import @ 1583921280000: ", energyImportData[energyImportData.length - 2]);
    console.log("Import delta: ", 14753260 - energyImportData[energyImportData.length - 2].value);
    console.log("Export @ 1583921280000: ", energyExportData[energyExportData.length - 2]);
    console.log("Export delta: ", 28724090 - energyExportData[energyExportData.length - 2].value);

    const csvText = Papa.unparse(combinedDatasets); 
    const csvBlob = new Blob([csvText], {type: 'text/csv'});
    const csvUrl = window.URL.createObjectURL(csvBlob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', csvUrl);
    a.setAttribute('download', 'import_export_data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {

    return(
      <MainContentWrapper>
        <h2><Icon name='chevron right'/>Homsphere 26 - Netting</h2>
        <H26NettingViewActionBar

          timeGranularityList={TimeGranularities}
          currentTimeGranularity={this.state.timeGranularity}
          
          currentFromDate={this.state.timeSpanStart}
          currentToDate={this.state.timeSpanEnd}
          timezone={this.isoTimezone}

          onFilterApply={this.handleFilterChange}
        />
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2rem'}}>
          <h2 style={{ color: primaryColor, margin: 0 }}>Energy Metering Data</h2>
          <div style={{marginRight: '1rem'}}>
            <strong>{DateTime.fromMillis(this.state.timeSpanStart).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
            <span style={{margin: '0 0.5rem'}}><Icon name={'long arrow alternate right'} size={'large'}/></span>
            <strong>{DateTime.fromMillis(this.state.timeSpanEnd).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
          </div>
        </div>
        <SectionLayout>
          <ChartTile 
            title={'Calculated Common Elements Consumption'}
            width={48}
            minWidth={36}
            collapsed={true}
            showDownloadButton={false} // Change to true to show the debug data download button for this tile
            onDownloadButtonClick={this.handleCalculatedCommonElementsConsDownloadButtonClick.bind(this)}
          >
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.commonElementsConsDatasetsForChart.data,
                  color: 'rgba(153, 102, 255, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1.8}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Sources',
                  items: [
                    { name: 'From Homes', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.total_homes_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'From Utility', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.total_utility_import_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Total', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.total_common_elements_consumption * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Metered Common Elements Consumption'} width={48} minWidth={36} collapsed={true}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.meteredCommonElementsConsDatasetsForChart.data,
                  color: 'rgba(153, 102, 255, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1.8}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.meteredCommonElementsConsDatasetsForChart.meta ? Math.round(this.state.meteredCommonElementsConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.meteredCommonElementsConsDatasetsForChart.meta ? Math.round(this.state.meteredCommonElementsConsDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.meteredCommonElementsConsDatasetsForChart.meta ? Math.round(this.state.meteredCommonElementsConsDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Utility Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.communityImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.communityExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.communityImportDatasetsForChart.meta ? Math.round(this.state.communityImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'To Homes', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.total_home_import_from_utility * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'To CE', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.total_utility_import_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityImportDatasetsForChart.meta ? Math.round(this.state.communityImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityImportDatasetsForChart.meta ? Math.round(this.state.communityImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.communityExportDatasetsForChart.meta ? Math.round(this.state.communityExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityExportDatasetsForChart.meta ? Math.round(this.state.communityExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityExportDatasetsForChart.meta ? Math.round(this.state.communityExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Villa 1 Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa1ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa1ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villa1ImportDatasetsForChart.meta ? Math.round(this.state.villa1ImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa1ImportDatasetsForChart.meta ? Math.round(this.state.villa1ImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa1ImportDatasetsForChart.meta ? Math.round(this.state.villa1ImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villa1ExportDatasetsForChart.meta ? Math.round(this.state.villa1ExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa1ExportDatasetsForChart.meta ? Math.round(this.state.villa1ExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa1ExportDatasetsForChart.meta ? Math.round(this.state.villa1ExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Export to Common', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.total_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Microgrid Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.total_microgrid_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.microgrid_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.microgrid_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Microgrid Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.total_microgrid_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.microgrid_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.microgrid_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.total_utility_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.utility_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.utility_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.total_utility_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.utility_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[0].meta.utility_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Consumption',
                  items: [
                    { name: 'CE Share (From Utility)', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.individual_home_common_elements_share_from_utility * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Villa 2 Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa2ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa2ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villa2ImportDatasetsForChart.meta ? Math.round(this.state.villa2ImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa2ImportDatasetsForChart.meta ? Math.round(this.state.villa2ImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa2ImportDatasetsForChart.meta ? Math.round(this.state.villa2ImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villa2ExportDatasetsForChart.meta ? Math.round(this.state.villa2ExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa2ExportDatasetsForChart.meta ? Math.round(this.state.villa2ExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa2ExportDatasetsForChart.meta ? Math.round(this.state.villa2ExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Export to Common', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.total_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Microgrid Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.total_microgrid_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.microgrid_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.microgrid_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Microgrid Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.total_microgrid_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.microgrid_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.microgrid_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.total_utility_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.utility_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.utility_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.total_utility_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.utility_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[1].meta.utility_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Consumption',
                  items: [
                    { name: 'CE Share (From Utility)', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.individual_home_common_elements_share_from_utility * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Villa 3 Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa3ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa3ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villa3ImportDatasetsForChart.meta ? Math.round(this.state.villa3ImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa3ImportDatasetsForChart.meta ? Math.round(this.state.villa3ImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa3ImportDatasetsForChart.meta ? Math.round(this.state.villa3ImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villa3ExportDatasetsForChart.meta ? Math.round(this.state.villa3ExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa3ExportDatasetsForChart.meta ? Math.round(this.state.villa3ExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa3ExportDatasetsForChart.meta ? Math.round(this.state.villa3ExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Export to Common', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.total_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Microgrid Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.total_microgrid_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.microgrid_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.microgrid_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Microgrid Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.total_microgrid_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.microgrid_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.microgrid_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.total_utility_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.utility_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.utility_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.total_utility_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.utility_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[2].meta.utility_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Consumption',
                  items: [
                    { name: 'CE Share (From Utility)', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.individual_home_common_elements_share_from_utility * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Villa 4 Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa4ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa4ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villa4ImportDatasetsForChart.meta ? Math.round(this.state.villa4ImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa4ImportDatasetsForChart.meta ? Math.round(this.state.villa4ImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa4ImportDatasetsForChart.meta ? Math.round(this.state.villa4ImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villa4ExportDatasetsForChart.meta ? Math.round(this.state.villa4ExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa4ExportDatasetsForChart.meta ? Math.round(this.state.villa4ExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa4ExportDatasetsForChart.meta ? Math.round(this.state.villa4ExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Export to Common', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.total_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Microgrid Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.total_microgrid_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.microgrid_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.microgrid_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Microgrid Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.total_microgrid_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.microgrid_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.microgrid_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.total_utility_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.utility_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.utility_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.total_utility_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.utility_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[3].meta.utility_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Consumption',
                  items: [
                    { name: 'CE Share (From Utility)', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.individual_home_common_elements_share_from_utility * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Villa 5 Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa5ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa5ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villa5ImportDatasetsForChart.meta ? Math.round(this.state.villa5ImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa5ImportDatasetsForChart.meta ? Math.round(this.state.villa5ImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa5ImportDatasetsForChart.meta ? Math.round(this.state.villa5ImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villa5ExportDatasetsForChart.meta ? Math.round(this.state.villa5ExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa5ExportDatasetsForChart.meta ? Math.round(this.state.villa5ExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa5ExportDatasetsForChart.meta ? Math.round(this.state.villa5ExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Export to Common', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.total_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Microgrid Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.total_microgrid_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.microgrid_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.microgrid_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Microgrid Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.total_microgrid_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.microgrid_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.microgrid_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.total_utility_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.utility_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.utility_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.total_utility_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.utility_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[4].meta.utility_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Consumption',
                  items: [
                    { name: 'CE Share (From Utility)', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.individual_home_common_elements_share_from_utility * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Villa 6 Import/Export'} width={100} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa6ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa6ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villa6ImportDatasetsForChart.meta ? Math.round(this.state.villa6ImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa6ImportDatasetsForChart.meta ? Math.round(this.state.villa6ImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa6ImportDatasetsForChart.meta ? Math.round(this.state.villa6ImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villa6ExportDatasetsForChart.meta ? Math.round(this.state.villa6ExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villa6ExportDatasetsForChart.meta ? Math.round(this.state.villa6ExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villa6ExportDatasetsForChart.meta ? Math.round(this.state.villa6ExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Export to Common', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.total_export_to_common_elements * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Microgrid Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.total_microgrid_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.microgrid_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.microgrid_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Microgrid Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.total_microgrid_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.microgrid_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.microgrid_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Import',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.total_utility_import * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.utility_import_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.utility_import_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Utility Export',
                  items: [
                    { name: 'Total', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.total_utility_export * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.utility_export_tou.soft_hours * 1000) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.communityNettingData.data ? Math.round(this.state.communityNettingData.data[5].meta.utility_export_tou.peak_hours * 1000) / 1000 : '-', unit: 'kWh' },
                  ]
                },
                {
                  title: 'Consumption',
                  items: [
                    { name: 'CE Share (From Utility)', value: this.state.communityNettingData.meta ? Math.round(this.state.communityNettingData.meta.individual_home_common_elements_share_from_utility * 1000) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          
        </SectionLayout>
      </MainContentWrapper>
    )
  }

}

const SectionLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

const mapStateToProps = (state) => ({
  user: state.auth.user,
  app: state.app,
  hesa: state.hesa
});

export default connect(mapStateToProps)(H26NettingView);