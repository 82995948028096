import { 
  AUTH_SET_STATE,
  AUTH_SET_USER
} from 'actions/types';


// Action creators
export const setAuthState = (authState) => {
  return {
    type: AUTH_SET_STATE,
    payload: authState
  }
}

export const setAuthUser = (authUser) => {
  return {
    type: AUTH_SET_USER,
    payload: authUser
  }
}