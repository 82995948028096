import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Dimmer, Loader } from 'semantic-ui-react';

import { setHesaApiAccessToken, setAppLoadingStateTo } from 'actions';

import { requestHesaApiAccessToken } from 'api/HesaApi';


class LoadingScreen extends Component {

  constructor(props) {
    super(props);
    if (this.props.user !== null) {
      this.getHesaApiAccessToken({
        client_id: this.props.user['custom:hesa_api_client_id'],
        client_secret: this.props.user['custom:hesa_api_secret'],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user === null && this.props.user !== null) {
      this.getHesaApiAccessToken({
        client_id: this.props.user['custom:hesa_api_client_id'],
        client_secret: this.props.user['custom:hesa_api_secret'],
      });
    }
  }

  getHesaApiAccessToken = async ({ client_id, client_secret }) => {
    await requestHesaApiAccessToken({ client_id, client_secret })
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          this.props.setHesaApiAccessToken(res.data.access_token);
          this.props.setAppLoadingStateTo(true);  // App is loaded when we have the API access token!
        }
      }).catch((err) => {
        // console.log(err);
        this.props.setAppLoadingStateTo(true);  // Set the loading state to true
        this.props.history.push('/settings');   // but redirect to the settings page!
      });
  }

  render() {
    return (
      <Dimmer active>
        <Loader>Loading in progress...</Loader>
      </Dimmer>
    )
  }

}

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.auth.user,
  hesa: state.hesa
});

export default withRouter(connect(mapStateToProps, { setHesaApiAccessToken, setAppLoadingStateTo })(LoadingScreen));