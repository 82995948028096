import { 
  HESA_SET_API_ACCESS_TOKEN
} from 'actions/types';

const INITIAL_STATE = {
  access_token: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HESA_SET_API_ACCESS_TOKEN:
      return { ...state, access_token: action.payload };
    default:
      return state;
    }
};