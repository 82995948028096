import { 
  AUTH_SET_STATE,
  AUTH_SET_USER,
} from 'actions/types';

/************************

Valid authState [string]:
  - signIn
  - signUp
  - confirmSignIn
  - confirmSignUp
  - forgotPassword
  - requireNewPassword
  - verifyContact
  - signedIn

*/

const INITIAL_STATE = {
  state: "signIn",
  user: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case AUTH_SET_USER:
      return { ...state, user: action.payload };

    case AUTH_SET_STATE:
      return { ...state, state: action.payload };

    default:
      return state;
  
    }
};