import { 
  APP_SET_LOADING_STATE,
  APP_SET_COMMUNITY_ID
} from 'actions/types';

// Action creators
export const setAppLoadingStateTo = (new_loading_state) => {
  const validate = (stateToValidate) => {
    if (typeof stateToValidate !== "boolean") {
      console.log('Invalid state passed to "setAppLoadingStateTo"', stateToValidate);
      console.log(stateToValidate, ' is not of BOOLEAN type');
      return null;
    }
    return stateToValidate;
  }
  return {
    type: APP_SET_LOADING_STATE,
    payload: validate(new_loading_state)
  }
}

export const setAppCommunityId = (new_community_id) => {
  return {
    type: APP_SET_COMMUNITY_ID,
    payload: new_community_id
  }
}