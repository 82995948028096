import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Authenticator, Greetings, SignUp } from 'aws-amplify-react';

import { Auth } from 'aws-amplify';

import { setAuthState, setAuthUser } from 'actions';

class CustomAuthenticator extends Component {

  handleAuthStateChange = async (newAuthState) => {
    if (newAuthState === 'signedIn') {
      await Auth.currentAuthenticatedUser()
        .then(cognitoUser => {
          this.props.setAuthState(newAuthState);
          this.props.setAuthUser(cognitoUser.attributes);
        }).catch(err => console.log(err));
    }
  }

  render() {
    return (
      <Authenticator
        onStateChange={(newAuthState) => this.handleAuthStateChange(newAuthState)}
        signUpConfig={signUpConfig}
        usernameAttributes='email'
        hide={[ 
          Greetings,
          SignUp
        ]}
      />
    )
  }

}

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Name',
      key: 'name',
      required: true,
      type: 'string',
      displayOrder: 1
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      type: 'email',
      displayOrder: 2
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      type: 'password',
      displayOrder: 3
    }
  ]
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app
});

export default connect(mapStateToProps, { setAuthState, setAuthUser })(CustomAuthenticator);