import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

// React Router imports
import { Route, Switch, Redirect, NavLink, withRouter } from 'react-router-dom';

// Components
import LeftNav from 'components/LeftNav';
import LogoutLink from 'containers/auth/LogoutLink';
import logo from 'assets/logo.svg';

// Views
import DashboardView from 'views/DashboardView';
import H22NettingView from 'views/H22NettingView/H22NettingView';
import H26NettingView from 'views/H26NettingView/H26NettingView';
import HomesView from 'views/HomesView/HomesView';
import ToolsView from 'views/ToolsView/ToolsView';

import DebugView from 'views/DebugView/DebugView';
import SettingsView from 'views/SettingsView/SettingsView';

// Theme variables
import { themeColors } from 'Theme';


class MainLayout extends Component {
  render() {

    const userEmail = this.props.user.email || '';
    const userIsDeveloper = userEmail.indexOf('@bitfuel.io') !== -1;

    return (
      <Layout>
        <NavLink to="/" className="logo-container">
          <img src={logo} alt="" className="logo" />
        </NavLink>
        <nav className="top-nav">
          <div className="top-nav-header">
            <h3>Statistical Data Monitoring</h3>
          </div>
          <div className="top-nav-menu">
            {/* <span>Community ID: {this.props.app.community_id}</span> */}
            <div className="user-info">
              <h5>{this.props.user.name}</h5>
              <span>({this.props.user.email})</span>
            </div>
            <div className="logout-link">
              <LogoutLink>Sign Out<Icon name='sign out'/></LogoutLink>
            </div>
          </div>
        </nav>
        <LeftNav showDevLinks={userIsDeveloper}/>
        {this.props.hesa.access_token !== null ? (
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard"/>}/>
            <Route path="/dashboard" component={DashboardView}/>
            <Route path="/h26-netting" component={H26NettingView}/>
            <Route path="/h22-netting" component={H22NettingView}/>
            <Route path="/homes" component={HomesView}/>
            {userIsDeveloper ? <Route path="/tools" component={ToolsView}/> : null}
            {userIsDeveloper ? <Route path="/debug" component={DebugView}/> : null}
            <Route path="/settings" component={SettingsView}/>
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        ) : (
          <SettingsView />
        )}
        
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  hesa: state.hesa,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps)(MainLayout));

const { 
  primaryColor,
  bodyTextColor,
  bodyBackgroundColor,
  navBackgroundColor
} = themeColors;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 15rem auto;
  grid-template-rows: 4rem auto;
  height: 100%;
  width: 100%;
  color: ${bodyTextColor};
  .logo-container {
    display: flex;
    align-items: center;
    background-color: ${navBackgroundColor};
    color: white;
    border-left: 0.3rem solid transparent;
    padding-left: 1.2rem;
    width: auto;
    .logo {
      max-height: 1.8rem;
      width: 85%;
    }
  }
  .top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    color: white;
    background-color: ${primaryColor};
    box-shadow: 0 2px 6px rgba(53,72,88,.3);
    border-bottom: 1px solid rgba(53,72,88,0.1);
    z-index: 1;
    .top-nav-menu {
      display: flex;
      justify-content: flex-end;
      margin-right: 1rem;
      i {
        margin: 0 0 0 0.5rem;
      }
      a {
        color: rgba(255, 255, 255, 0.7);
        transition: all 0.3s ease-in-out;
        &:hover {
          color: rgba(255, 255, 255, 1);
          transition: all 0.1s ease-in-out;
        }
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      padding: 0rem 1rem;
      h5 {
        margin: 0 0.7rem;
      }
      span {
        color: rgba(255, 255, 255, 0.7);
      }
    }
    .logout-link {
      padding: 0.5rem 1rem;
      border-left: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  .left-nav {
    background-color: rgb(238, 238, 238);
    z-index: 2;
  }
  main {
    background-color: ${bodyBackgroundColor};
    overflow-y: auto;
  }
`