import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import { Form, Button } from 'semantic-ui-react'

import { setAuthUser } from 'actions';

import Timezones from 'Timezones';

import { themeColors } from 'Theme';

const {
  primaryColor
} = themeColors;

const timezoneOptions = Object.values(Timezones);

class TimezoneSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user_timezone: this.props.settings.user_timezone || 'america_toronto',
      form_submit_is_disabled: true
    }
  }

  saveNewSettings = async () => {
    let user = await Auth.currentAuthenticatedUser();
    let result = await Auth.updateUserAttributes(user, {
      'custom:user_timezone': this.state.user_timezone
    });
    if (result === 'SUCCESS') {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(cognitoUser => {
          this.props.setAuthUser(cognitoUser.attributes);
        }).catch(err => console.log(err));
      this.setState({
        form_submit_is_disabled: true
      });
    }
  }

  handleFieldValueChange = (event, data) => {
    this.setState({
      [data.name]: data.value,
      form_submit_is_disabled: false
    })
  }

  handleSaveButtonClick = (event) => {
    this.saveNewSettings();
  }

  render() {
    return(
      <Form onSubmit={this.handleSubmitButtonClick}>
        <h3 style={{color: primaryColor}}>Timezone Preferences</h3>
        <Form.Group widths={12} style={{marginTop: '2rem'}}>
          <Form.Field
            width={2}
            control={Form.Select}
            label='User Timezone'
            options={timezoneOptions}
            value={this.state.user_timezone}
            name={'user_timezone'}
            onChange={this.handleFieldValueChange}
          />
        </Form.Group>
        <Button
          onClick={this.handleSaveButtonClick}
          content={'Save Settings'}
          style={{marginTop: '1.5rem'}}
          color='blue'
          disabled={this.state.form_submit_is_disabled}
        />
      </Form>
    )
  }

}

export default connect(null, { setAuthUser })(TimezoneSettings);