import React, { Component } from 'react';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';
import { themeColors } from 'Theme';

const {
  primaryColor,
  secondaryColor,
  labelTextColor
} = themeColors;


class ChartStats extends Component {

  static defaultProps = {
    sections: [],
    showStats: false
  }

  constructor(props) {
    super(props);

    console.log(this.props.numberFormat);
    
    this.state = {
      show_stats: this.props.showStats
    }
  }

  handleChartStatsTitleClick = () => {
    // console.log(this.state.show_stats);
    this.setState({
      show_stats: !this.state.show_stats
    })
  }

  renderSections = (sections) => {
    return sections.map((section, index) =>
      <ChartStatsSectionContainer key={index}>
        <ChartStatsSectionTitle>{section.title}</ChartStatsSectionTitle>
        {this.renderItems(section.items)}
      </ChartStatsSectionContainer>
    )
  }

  renderItems = (items) => {
    return items.map((item, index) => 
      <div key={index} style={{display: 'inline-block', marginRight: '2rem', marginTop: '1rem'}}>
        <div style={{color: labelTextColor}}>
          <strong>{item.name}</strong>
        </div>
        <div style={{ marginTop: '0.3rem' }}>
          <h3 style={{ display: 'inline' }}>{
            (typeof(item.value) === 'number' ? (new Intl.NumberFormat(this.props.numberFormat, {minimumFractionDigits: 3}).format(item.value)) : '-') + ' '
          }</h3><span style={{color: labelTextColor}}>{item.unit}</span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <ChartStatsWrapper>
        <ChartStatsTitle onClick={this.handleChartStatsTitleClick}><h4>Statistics<Icon name={this.state.show_stats ? 'chevron circle down' : 'chevron circle right'}/></h4></ChartStatsTitle>
        <ChartStatsContentWrapper collapsed={!this.state.show_stats}>{this.renderSections(this.props.sections)}</ChartStatsContentWrapper>
      </ChartStatsWrapper>
    )
  }
}

const ChartStatsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ChartStatsTitle = styled.div`
  color: ${secondaryColor};
  width: fit-content;
  padding: 0.5rem 0.5rem 0.5rem 0;
  :hover{
    cursor: pointer;
  }
  i {
    margin-left: 10px;
  }
`

const ChartStatsContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: ${props => props.collapsed ? '0rem' : '1rem'};
  opacity: ${props => props.collapsed ? 0 : 1};
  max-height: ${props => props.collapsed ? '0rem' : '50rem'};
  transition: opacity 0.6s ease-in-out, max-height 1s ease-in-out, margin-top 1s ease-in-out;
`

const ChartStatsSectionContainer = styled.div`
  display: inline-block;
  margin-right: 1%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  border-left: 2px solid ${primaryColor};
  /* font-variant-numeric: tabular-nums; */
`

const ChartStatsSectionTitle = styled.h4`
  color: ${primaryColor};
  margin-bottom: 0rem;
`

export default ChartStats;