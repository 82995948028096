// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a4d7ef02-cb5c-439c-8738-c572ce65d303",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3SM7TEK0k",
    "aws_user_pools_web_client_id": "22au0l73p61a7vu47hhhki237s",
    "oauth": {},
    "aws_content_delivery_bucket": "hs-stats-20190811222824-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2lxttntlwyz4m.cloudfront.net"
};


export default awsmobile;
