import React, { Component, Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-luxon'  // Needed to use Luxon for ChartJs

class BarChart extends Component {

  static defaultProps = {
    width: 99.9,
    minWidth: 38,
    aspectRatio: 1,
    dataSets: [],
    timeScale: 'hour',
    timeMin: null,
    timeMax: null,
    yAxisLabel: '',
    timezone: ''
  }

  getDatasetsFromProps = (dataSets) => {
    let outputBuffer = [];
    dataSets.forEach((e, i, arr) => {
      outputBuffer.push({
        type: 'bar',
        label: e.label,
        data: e.data,
        backgroundColor: e.color ? e.color : 'rgba(255, 205, 86, 1)',
        borderWidth: 0
      })
    });
    return [...outputBuffer];
  }

  render() {
    return(
      <Fragment>
        <Bar 
          height={this.props.width * this.props.aspectRatio}
          data={{ datasets: this.getDatasetsFromProps(this.props.dataSets) }}
          options={{
            responsive: true,
            aspectRatio: this.props.aspectRatio,
            maintainAspectRatio: true,
            legend: {
              display: this.props.legend ? true : false,
              position: 'bottom',
              labels: {
                boxWidth: 12,
                padding: 30
              }
            },
            scales: {
              xAxes: [{
                stacked: this.props.stacked ? true : false,
                type: 'time',
                distribution: 'linear',
                adapters: {
                  date: {
                    zone: this.props.timezone
                  }
                },
                time: {
                  min: this.props.timeMin,
                  max: this.props.timeMax,
                  unit: this.props.timeScale
                },
                ticks: {
                  source: 'auto',
                  maxRotation: 0,
                  autoSkip: true
                },
                barPercentage: 0.9,
                // barThickness: 'flex',
                categoryPercentage: 1,
                minBarLength: 2,
                // gridLines: {
                //     offsetGridLines: false
                // }
              }],
              yAxes: [{
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 0.1
                },
                scaleLabel: {
                  display: true,
                  labelString: this.props.yAxisLabel,
                  fontStyle: 'bold'
                }
              }]
            }
          }}
        />
      </Fragment>
    )
  }
  
}


export default BarChart;