import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import DatePicker from 'react-datepicker2';
import moment from 'moment-timezone';

import { Button, Form, Dropdown, Icon } from 'semantic-ui-react';


class H26NettingViewActionBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fromDate: props.currentFromDate,
      toDate: props.currentToDate,
      timeGranularity: 5,
      applyIsDisabled: true
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual({...this.state, applyIsDisabled: null} , {...prevState, applyIsDisabled: null})) {
      this.setState({
        applyIsDisabled: false
      });
    }
    // console.log("From: " + this.state.fromDate + " / To: " + this.state.toDate);
  }

  handleFromDateChange = (date) => {
    const currentDate = this.state.fromDate;
    const newDate = moment(date).tz(this.props.timezone).valueOf();
    if (newDate !== currentDate) {
      if (newDate >= this.state.toDate) {
        this.setState({
          fromDate: moment(newDate).tz(this.props.timezone).startOf('day').valueOf(),
          toDate: moment(newDate).add(1, 'day').valueOf()
        });
      } else {
        this.setState({
          fromDate: moment(newDate).tz(this.props.timezone).startOf('day').valueOf()
        });
      }
    }
  }
  
  handleToDateChange = (date) => {
    const currentDate = this.state.toDate;
    const newDate = moment(date).tz(this.props.timezone).valueOf();
    if (newDate !== currentDate) {
      if (moment(newDate).tz(this.props.timezone).startOf('day').valueOf() <= this.state.fromDate) {
        this.setState({
          fromDate: moment(newDate).add(-1, 'day').valueOf(),
          toDate: moment(newDate).tz(this.props.timezone).startOf('day').valueOf()
        });
      } else {
        this.setState({
          toDate: moment(newDate).tz(this.props.timezone).startOf('day').valueOf()
        });
      }
    }
  }

  handleTimeGranularityChange = (e, { value }) => {
    this.setState({
      timeGranularity: value
    });
  }

  handleApplyFilterClick = () => {
    const state = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      timeGranularity: this.state.timeGranularity
    };
    this.props.onFilterApply(state);
    this.setState({
      applyIsDisabled: true
    });
  }

  render() {

    // const communityDropdownList = Object.values(this.props.communityList);
    const timeGranularityDropdownList = Object.values(this.props.timeGranularityList);

    return (
      <ActionBarContainer>
        <ActionBarSection>
          <ActionBarSectionContent>
            {/* <div>
              <Form>
                <Form.Field>
                  <label>Show data for</label>
                  <Dropdown placeholder='Community ID' search selection
                    options={communityDropdownList}
                    onChange={this.handleCommunityIdChange}
                    value={this.state.communityId}
                  />
                </Form.Field>
              </Form>
            </div>
            <SeparatorDot/> */}
            <ActionBarDatePicker>
              <div>
                <Form>
                  <Form.Field>
                    <label>From Date</label>
                    <DatePicker
                      timePicker={false}
                      onChange={date => this.handleFromDateChange(date)}
                      inputFormat="YYYY/MM/DD - hh:mmA"
                      value={moment(this.state.fromDate).tz(this.props.timezone).startOf('day')}
                    />
                  </Form.Field>
                </Form>
              </div>
              <Icon name={'long arrow alternate right'} size={'large'}/>
              <div>
                <Form>
                  <Form.Field>
                    <label>To Date</label>
                    <DatePicker
                      timePicker={false}
                      onChange={date => this.handleToDateChange(date)}
                      inputFormat="YYYY/MM/DD - hh:mmA"
                      value={moment(this.state.toDate).tz(this.props.timezone).startOf('day')}
                      min={moment(this.state.fromDate).tz(this.props.timezone).add(1, 'day').startOf('day')}
                    />
                  </Form.Field>
                </Form>
              </div>
            </ActionBarDatePicker>
            <SeparatorDot/>
            <div>
              <Form>
                <Form.Field>
                  <label>Time Granularity</label>
                  <Dropdown placeholder='Granularity' selection
                    options={timeGranularityDropdownList}
                    onChange={this.handleTimeGranularityChange}
                    value={this.state.timeGranularity}
                  />
                </Form.Field>
              </Form>
            </div>
          </ActionBarSectionContent>
          <div>
            <Button
              color={this.state.applyIsDisabled ? null : 'teal'}
              onClick={this.handleApplyFilterClick}
              disabled={this.state.applyIsDisabled}
            >Apply<Icon name={'filter'} style={{marginLeft: '0.5rem', marginRight: 0}}/></Button>
          </div>
        </ActionBarSection>
      </ActionBarContainer>
    )
  }

}

const ActionBarContainer = styled.div`
  padding: 1rem 0 2rem 0;
  margin-right: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const ActionBarSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  form {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  button.ui {
    margin: 0;
    margin-top: 1rem;
  }
  i {
    margin-bottom: 0.6rem;
    margin-right: 0.8rem;
    opacity: 0.7; 
  }
`

const ActionBarSectionContent = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`

const ActionBarDatePicker = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`

const SeparatorDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-right: 2rem;
  margin-left: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
`

export default H26NettingViewActionBar;