import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

class ChartTile extends Component {

  static defaultProps = {
    title: '',
    minWidth: 38,
    width: 100,
    height: null,
    showDownloadButton: false,
    collapsed: false
  }

  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsed
    }
  }

  handleTileCollapseButtonClick = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <Tile 
        minWidth={this.props.minWidth}
        width={this.props.width}
        minHeight={this.props.minHeight}
        height={this.props.height}
      >
        <TileHeader>
          <ChartTitle>{this.props.title}</ChartTitle>
          <TileMenu>
            {this.props.showDownloadButton &&
              <TileMenuItem onClick={this.props.onDownloadButtonClick}>
                <Icon name={'download'} />
              </TileMenuItem>
            }
            <TileMenuItem onClick={this.handleTileCollapseButtonClick.bind(this)}>
              <Icon name={this.state.collapsed ? 'window maximize outline' : 'window minimize outline'} />
            </TileMenuItem>
          </TileMenu>
        </TileHeader>
        <TileContent maxHeight={this.state.collapsed ? 0 : 100}>
          {this.props.children}
        </TileContent>
      </Tile>

    )
  }
}

const Tile = styled.div`
  display: inline-block;
  margin: 1rem 1rem 1rem 0;
  padding: 1.1rem 1.5rem;
  min-width: ${props => props.minWidth + 'rem'};
  width: ${props => props.width + '%'};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  border-radius: 4px;
  background-color: white;
  box-shadow: 1px 1px 7px -1px rgba(0,0,0,0.28);
`

const TileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TileContent = styled.div`
  margin-top: ${props => props.maxHeight === 0 ? '0rem' : '1.5rem'};
  max-height: ${props => props.maxHeight + 'rem'};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`

const TileMenu = styled.div`
  display: flex;
  justify-content: space-between;
`

const TileMenuItem = styled.div`
  color: rgba(0, 0, 0, 0.7);
  padding: 0.4rem;
  margin-left: 0.4rem;
  border-radius: 4px;
  i {
    margin: 0;
  }
  transition: all 0.2s ease-in-out;
  :hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-in-out;
  }
`

const ChartTitle = styled.h3`
  margin: 0;
  color: #00b5ad;
`

export default ChartTile;