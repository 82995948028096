import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { Icon } from 'semantic-ui-react';

import styled from 'styled-components';

import { themeColors } from 'Theme';

const { 
  navBackgroundColor, 
  navTextColor, 
  navActiveLinkBackgroundColor, 
  navActiveLinkTextColor, 
  primaryColor
} = themeColors;

const LeftNav = (props) => {
  return (
    <LeftNavContainer>
      <LeftNavList>
        <LeftNavItem>
          <NavLink to="/dashboard" activeStyle={activeNavLinkStyle}>
            <Icon name='dashboard'/><span>Dashboard</span>
          </NavLink>
        </LeftNavItem>
        {/* <LeftNavHeader>
          <h5>Communities</h5>
        </LeftNavHeader> */}
        <LeftNavItem>
          <NavLink to="/h26-netting" activeStyle={activeNavLinkStyle}>
            <Icon name='users'/><span>H26 - Thônex GE</span>
          </NavLink>
        </LeftNavItem>
        <LeftNavItem>
          <NavLink to="/h22-netting" activeStyle={activeNavLinkStyle}>
            <Icon name='users'/><span>H22 - Thônex GE</span>
          </NavLink>
        </LeftNavItem>
        <LeftNavItem>
          <NavLink to="/homes" activeStyle={activeNavLinkStyle}>
            <Icon name='home'/><span>Homes</span>
          </NavLink>
        </LeftNavItem>
        {props.showDevLinks ? 
          <LeftNavItem>
            <NavLink to="/tools" activeStyle={activeNavLinkStyle}>
              <Icon name='wrench'/><span>Tools</span>
            </NavLink>
          </LeftNavItem>
          : null
        }
      </LeftNavList>
      <LeftNavList>
        {props.showDevLinks ? 
          <LeftNavItem>
            <NavLink to="/debug" activeStyle={activeNavLinkStyle}>
              <Icon name='bug'/><span>Debug</span>
            </NavLink>
          </LeftNavItem>
          : null
        }
        <LeftNavItem>
          <NavLink to="/settings" activeStyle={activeNavLinkStyle}>
            <Icon name='setting'/><span>Settings</span>
          </NavLink>
        </LeftNavItem>
        <AppVersion>{process.env.REACT_APP_NAME + '  v' + process.env.REACT_APP_VERSION}</AppVersion>
      </LeftNavList>
    </LeftNavContainer>
  )
}

const activeNavLinkStyle = {
  backgroundColor: navActiveLinkBackgroundColor,
  borderLeft: `0.3rem solid ${primaryColor}`,
  color: navActiveLinkTextColor,
  fontWeight: 'bold',
}

const LeftNavContainer = styled.nav`
  background-color: ${navBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
`

const LeftNavList = styled.ul`
  padding: 0px;
  list-style: none;
`

const LeftNavItem = styled.li`
  a {
    display: block;
    padding: 1.2rem;
    color: ${navTextColor};
    border-left: 0.3rem solid transparent;
    transition: all 0.3s ease-in-out;
    &:hover, &:focus {
      color: white;
      transition: all 0.2s ease-in-out;
    }
    i {
      font-size: 1.2rem;
      margin-right: 1.5rem;
    }
  }
`

// const LeftNavHeader = styled.li`
//   h1, h2, h3, h4, h5, h6 {
//     /* font-size: 1.5rem; */
//     display: block;
//     margin: 2rem 1.5rem 0rem 1.5rem;
//     padding: 1.2rem 1.2rem 1.2rem 0rem;
//     color: ${navTextColor};
//     border-top: 1px solid rgba(255, 255, 255, 0.05);
//   }
// `


const AppVersion = styled.div`
  padding: 1.5rem 0 0 1.5rem;
  color: rgba(255, 255, 255, 0.3);
`

export default withRouter(LeftNav);

