// Combine all reducers thoughout the app for Redux
import { combineReducers } from 'redux';

import AppReducer from 'reducers/AppReducer';
import AuthReducer from 'reducers/AuthReducer';
import HesaReducer from 'reducers/HesaReducer';

export default combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  hesa: HesaReducer,
});