import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Icon } from 'semantic-ui-react';

import MainContentWrapper from 'components/commons/MainContentWrapper';

class DashboardView extends Component {

  render() {
    return(
      <MainContentWrapper>
        <h2><Icon name='chevron right'/>Dashboard View</h2>
      </MainContentWrapper>
    )
  }

}

const mapStateToProps = (state) => ({
  hesa: state.hesa
})

export default connect(mapStateToProps)(DashboardView);