import axios from 'axios';

export const requestHesaApiAccessToken = (params) => {
  const formData = new FormData();
  formData.set('grant_type', 'client_credentials');
  formData.set('client_id', params.client_id);
  formData.set('client_secret', params.client_secret);
  formData.set('scope', '*');
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_HESA_API_HOST + process.env.REACT_APP_HESA_API_TOKEN_REQUEST_PATH,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const fetchHesaHomeFiguresData = (params) => {
  return axios({
    method: 'GET',
    url:  process.env.REACT_APP_HESA_API_HOST + 
          process.env.REACT_APP_HESA_API_ROOT + 
          process.env.REACT_APP_HESA_API_HOME_DATA_PATH + '/' + 
          params.home_id + '/customfigures?from=' +
          params.from + "&to=" +
          params.to,
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + params.api_access_token
    }
  }).then(res => {
    try {
      return { 
        home_id: params.home_id,
        data: res.data.data
      }
    } catch(err) {
      console.error(err);
    }
  })
}



export const fetchHesaHomeData = (params) => {
  return axios({
    method: 'GET',
    url:  process.env.REACT_APP_HESA_API_HOST + 
          process.env.REACT_APP_HESA_API_ROOT + 
          process.env.REACT_APP_HESA_API_HOME_DATA_PATH + '/' + 
          params.home_id + '/customaddressfigures?address=' +
          params.address + '&from=' + 
          params.from + '&to=' +
          params.to,
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + params.api_access_token
    }
  }).then(res => {
    try {
      return { 
        home_id: res.data.meta.home_id,
        data: res.data.data,
        address: res.data.meta.address,
        from: res.data.meta.from,
        to: res.data.meta.to
      }
    } catch(err) {
      console.error(err);
    }
  })
}


export const fetchHesaCommunityData = (params) => {
  return axios({
    method: 'GET',
    url:  process.env.REACT_APP_HESA_API_HOST + 
          process.env.REACT_APP_HESA_API_ROOT +
          process.env.REACT_APP_HESA_API_COMMUNITY_DATA_PATH + '/' +
          params.community_id + '/customaddressfigures?address=' +
          params.address + '&from=' + 
          params.from + '&to=' +
          params.to,
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + params.api_access_token
    }
  }).then(res => {
    try {
      return { 
        community_id: res.data.meta.community_id,
        data: res.data.data,
        address: res.data.meta.address,
        from: res.data.meta.from,
        to: res.data.meta.to
      }
    } catch(err) {
      console.error(err);
    }
  })
}