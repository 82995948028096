import { 
  HESA_SET_API_ACCESS_TOKEN
} from 'actions/types';


// Action creators
export const setHesaApiAccessToken = (access_token) => {
  return {
    type: HESA_SET_API_ACCESS_TOKEN,
    payload: access_token
  }
}