import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DateTime } from 'luxon';

import { Icon } from 'semantic-ui-react';

import MainContentWrapper from 'components/commons/MainContentWrapper';
import ChartTile from 'components/commons/ChartTile';
// import ChartStats from 'components/commons/ChartStats';
import H26NettingViewActionBar from 'views/H26NettingView/H26NettingViewActionBar';
import TimeseriesBarChart from 'components/charts/TimeseriesBarChart';

import Timezones from 'Timezones';
import Communities from 'Communities';
import TimeOfUseSchedules from 'TimeOfUseSchedules';

import {
  fetchHesaHomeData
} from 'api/HesaApi';

import { themeColors } from 'Theme';

import WebWorker from 'views/DebugView/DebugView.worker';
import { setAppCommunityId } from 'actions';


const TimeGranularities = {
  1: {  
    key: 'g1',
    text: '1 minute',
    value: 1
  },
  2: {  
    key: 'g2',
    text: '2 minutes',
    value: 2
  },
  3: {  
    key: 'g3',
    text: '5 minutes',
    value: 5
  },
  4: {  
    key: 'g4',
    text: '10 minutes',
    value: 10
  },
  5: {  
    key: 'g5',
    text: '15 minutes',
    value: 15
  },
  6: {  
    key: 'g6',
    text: '30 minutes',
    value: 30
  },
  7: {  
    key: 'g7',
    text: '60 minutes',
    value: 60
  },
  8: {  
    key: 'g8',
    text: '120 minutes',
    value: 120
  }
}


const {
  primaryColor
} = themeColors;

const defaultTimezone = 'america_toronto';

class DebugView extends Component {

  constructor(props) {
    super(props);

    this.DebugViewWorker = new WebWorker();
    this.DebugViewWorker.onmessage = ev => this.handleMessageFromWorker(ev);

    this.communityTimezone = Communities[this.props.app.community_id].timezone;
    this.userTimezone = this.props.user['custom:user_timezone'] || defaultTimezone;

    this.isoTimezone = Timezones[this.userTimezone].text;
    this.numberFormat = Timezones[this.userTimezone].number_local;

    this.state = {
      timeGranularity: 5,

      villa5ImportDatasetsForChart: {data: [], meta: null},
      villa5ExportDatasetsForChart: {data: [], meta: null},

      timeSpanStart: DateTime.local().setZone(this.isoTimezone).minus({days:1}).startOf('day').toMillis(),
      timeSpanEnd: DateTime.local().setZone(this.isoTimezone).startOf('day').toMillis()
    }

  }

  componentDidMount() {
    this.getDataFromApiForState();
  }

  clearAllChartDatasets() {
    this.setState({
      villa5ImportDatasetsForChart: {data: [], meta: null},
      villa5ExportDatasetsForChart: {data: [], meta: null},
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Only go fetch new data if the timespan has changed
    // TODO: Add condition for community change as well
    if (
      this.state.timeSpanStart !== prevState.timeSpanStart || 
      this.state.timeSpanEnd !== prevState.timeSpanEnd || 
      this.props.app.community_id !== prevProps.app.community_id ||
      this.state.timeGranularity !== prevState.timeGranularity
    ) {
      this.clearAllChartDatasets();
      this.getDataFromApiForState();
    }
  }

  componentWillUnmount() {
    // Kill the web worker before we leave
    this.DebugViewWorker.terminate();
  }

  handleMessageFromWorker = (event) => {

    const data = event.data.data || null;
    const meta = event.data.meta || null;

    switch (event.data.ref) {
      // Villa 5
      case 'villa_5_import':
        this.setState({ villa5ImportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      case 'villa_5_export':
        this.setState({ villa5ExportDatasetsForChart: {data, meta}, communityAndVillaDatasetsUpdateCounter: this.state.communityAndVillaDatasetsUpdateCounter + 1 });
        break;
      default:
        break;
    }

  }


  getDataFromApiForState() {

    const timespanPadding = 60000 * 6;

    const defaultGetDatasetsForChartWorkerData = {
      command: 'getDatasetsForChart',
      ref: '',
      data: [],
      start_time: this.state.timeSpanStart,
      end_time: this.state.timeSpanEnd,
      period: 60000,  // Period of the data used for normalization
      ticks: this.state.timeGranularity,
      multiplier: 1,
      timezone: this.isoTimezone,
      tou_schedules: TimeOfUseSchedules,
      calculate_tou: true
    };

    /*******************************************
     *    VILLA 5
     *******************************************/
    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 5,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.DebugViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_5_import',
        data: res.data,
        multiplier: -1
      });
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: 5,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding,
    }).then(res => {
      this.DebugViewWorker.postMessage({
        ...defaultGetDatasetsForChartWorkerData,
        ref: 'villa_5_export',
        data: res.data
      });
    }).catch(err => console.log(err));

  }

  handleFilterChange = (filter) => {

    const timeSpanStart = DateTime.fromMillis(filter.fromDate.valueOf()).setZone(this.isoTimezone).startOf('day').toMillis();
    const timeSpanEnd = DateTime.fromMillis(filter.toDate.valueOf()).setZone(this.isoTimezone).startOf('day').toMillis();

    this.props.setAppCommunityId(filter.communityId);
    this.setState({
      timeSpanStart,
      timeSpanEnd,
      timeGranularity: filter.timeGranularity
    })
    
  }

  render() {

    return(
      <MainContentWrapper>
        <h2><Icon name='chevron right'/>Community Statistics</h2>
        <H26NettingViewActionBar 
          communityList={Communities}
          currentCommunityId={this.props.app.community_id}

          timeGranularityList={TimeGranularities}
          currentTimeGranularity={this.state.timeGranularity}
          
          currentFromDate={this.state.timeSpanStart}
          currentToDate={this.state.timeSpanEnd}
          timezone={this.isoTimezone}

          onFilterApply={this.handleFilterChange}
        />
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2rem'}}>
          <h2 style={{ color: primaryColor, margin: 0 }}>Energy Metering Data</h2>
          <div style={{marginRight: '1rem'}}>
            <strong>{DateTime.fromMillis(this.state.timeSpanStart).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
            <span style={{margin: '0 0.5rem'}}><Icon name={'long arrow alternate right'} size={'large'}/></span>
            <strong>{DateTime.fromMillis(this.state.timeSpanEnd).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
          </div>
        </div>
        <SectionLayout>
          <ChartTile title={'Villa 5 Import/Export'} width={48} minWidth={36}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villa5ImportDatasetsForChart.data,
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villa5ExportDatasetsForChart.data,
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              timeScale={'hour'}
              timeMin={this.state.timeSpanStart}
              timeMax={this.state.timeSpanEnd}
              yAxisLabel={'Energy (kWh)'}
              aspectRatio={1.8}
            />
          </ChartTile>
        </SectionLayout>
      </MainContentWrapper>
    )
  }

}

const SectionLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

const mapStateToProps = (state) => ({
  user: state.auth.user,
  app: state.app,
  hesa: state.hesa
});

export default connect(mapStateToProps, { setAppCommunityId })(DebugView);