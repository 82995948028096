const communities = {
  1: {  
    key: 'c1',
    text: 'H26 - Cleomes (Thônex)',
    ref: 'H26',
    timezone: 'europe_zurich',
    value: 1,
    database_id: 1,
    homes: [
      {
        key: 'h1-1',
        name: 'H26 - Villa 1',
        ref: 'H26_V1',
        database_id: 1
      },
      {
        key: 'h1-2',
        name: 'H26 - Villa 2',
        ref: 'H26_V2',
        database_id: 2
      },
      {
        key: 'h1-3',
        name: 'H26 - Villa 3',
        ref: 'H26_V3',
        database_id: 3
      },
      {
        key: 'h1-4',
        name: 'H26 - Villa 4',
        ref: 'H26_V4',
        database_id: 4
      },
      {
        key: 'h1-5',
        name: 'H26 - Villa 5',
        ref: 'H26_V5',
        database_id: 5
      },
      {
        key: 'h1-6',
        name: 'H26 - Villa 6',
        ref: 'H26_V6',
        database_id: 6
      }
    ]
  },
  2: {  
    key: 'c2',
    text: 'H22 - Cleomes (Thônex)',
    ref: 'H22',
    timezone: 'europe_zurich',
    value: 2,
    database_id: 2,
    homes: [
      {
        key: 'h2-1',
        name: 'H22 - Villa 1',
        ref: 'H22_V1',
        database_id: 7
      },
      {
        key: 'h2-2',
        name: 'H22 - Villa 2',
        ref: 'H22_V2',
        database_id: 8
      },
      {
        key: 'h2-3',
        name: 'H22 - Villa 3',
        ref: 'H22_V2',
        database_id: 9
      },
      {
        key: 'h2-4',
        name: 'H22 - Villa 4',
        ref: 'H22_V4',
        database_id: 10
      }
    ]
  }
}

export default communities;