// React and Redux imports
import React, { Component } from 'react';
import { connect } from 'react-redux';

// React Router imports
import { BrowserRouter as Router } from 'react-router-dom';

import CustomAuthenticator from 'containers/auth/CustomAuthenticator';

import MainScreen from 'screens/MainScreen';
import LoadingScreen from 'screens/LoadingScreen';

import { setAuthState, setAuthUser } from 'actions';

class AppContainer extends Component {
  render() {
    return (
      <Router>
        {this.props.auth.state === "signedIn" && this.props.auth.user !== null ? (
            this.props.app.app_is_loaded === true ? <MainScreen /> : <LoadingScreen />
          ) : <CustomAuthenticator />
        }
      </Router>
    )
  }   
}

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth
});

export default connect(mapStateToProps, { setAuthState, setAuthUser })(AppContainer);