import { 
  APP_SET_LOADING_STATE,
  APP_SET_COMMUNITY_ID
} from 'actions/types';

const INITIAL_STATE = {
  app_is_loaded: false,
  community_id: 1
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case APP_SET_LOADING_STATE:
      return { ...state, app_is_loaded: action.payload };

    case APP_SET_COMMUNITY_ID:
      return { ...state, community_id: action.payload };

    default:
      return state;
  
  }

};
