import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DateTime } from 'luxon';

import { Icon } from 'semantic-ui-react';

import MainContentWrapper from 'components/commons/MainContentWrapper';
import ChartTile from 'components/commons/ChartTile';
import ChartStats from 'components/commons/ChartStats';

import HomesViewActionBar from 'views/HomesView/HomesViewActionBar';

import DoughnutChart from 'components/charts/DoughnutChart';
import DiscreteBarChart from 'components/charts/DiscreteBarChart';
import TimeseriesBarChart from 'components/charts/TimeseriesBarChart';

import Timezones from 'Timezones';
import Communities from 'Communities';
import TimeOfUseSchedules from 'TimeOfUseSchedules';

import { 
  fetchHesaHomeFiguresData,
  fetchHesaHomeData
} from 'api/HesaApi';

import WebWorker from 'views/HomesView/HomesView.worker';

import { themeColors, chartColors } from 'Theme';

const { primaryColor } = themeColors;


const TimeGranularities = {
  1: {  
    key: 'g1',
    text: '1 minute',
    value: 1
  },
  2: {  
    key: 'g2',
    text: '2 minutes',
    value: 2
  },
  3: {  
    key: 'g3',
    text: '5 minutes',
    value: 5
  },
  4: {  
    key: 'g4',
    text: '10 minutes',
    value: 10
  },
  5: {  
    key: 'g5',
    text: '15 minutes',
    value: 15
  },
  6: {  
    key: 'g6',
    text: '30 minutes',
    value: 30
  },
  7: {  
    key: 'g7',
    text: '60 minutes',
    value: 60
  }
}

const defaultTimezone = 'america_toronto';

class HomesView extends Component {

  constructor(props) {
    super(props);

    this.HomesViewWorker = new WebWorker();
    this.HomesViewWorker.onmessage = ev => this.handleMessageFromWorker(ev);

    this.communityTimezone = Communities[this.props.app.community_id].timezone;
    this.userTimezone = this.props.user['custom:user_timezone'] || defaultTimezone;

    this.isoTimezone = Timezones[this.communityTimezone].text;
    this.numberFormat = Timezones[this.userTimezone].number_local;

    this.state = {
      timeGranularity: 60,

      homeData: {},

      homeIds: [
        {  
          key: 'c1h1',
          text: 'H26 - Villa 1',
          value: 1,
          id: 1,
        },
        {  
          key: 'c1h2',
          text: 'H26 - Villa 2',
          value: 2,
          id: 2,
        },
        {  
          key: 'c1h3',
          text: 'H26 - Villa 3',
          value: 3,
          id: 3,
        },
        {  
          key: 'c1h4',
          text: 'H26 - Villa 4',
          value: 4,
          id: 4,
        },
        {  
          key: 'c1h5',
          text: 'H26 - Villa 5',
          value: 5,
          id: 5,
        },
        {  
          key: 'c1h6',
          text: 'H26 - Villa 6',
          value: 6,
          id: 6,
        },
        {  
          key: 'c2h1',
          text: 'H22 - Villa 1',
          value: 7,
          id: 7,
        },
        {  
          key: 'c2h2',
          text: 'H22 - Villa 2',
          value: 8,
          id: 8,
        },
        {  
          key: 'c2h3',
          text: 'H22 - Villa 3',
          value: 9,
          id: 9,
        },
        {  
          key: 'c2h4',
          text: 'H22 - Villa 4',
          value: 10,
          id: 10,
        },
      ],
      
      currentlySelectedHomeId: 1,

      largeAppliancesConsDatasetsForChart: null,
      heatHotWaterConsDatasetsForChart: null,
      ventilationConsDatasetsForChart: null,
      outletsAndBlindsConsDatasetsForChart: null,
      lightsConsDatasetsForChart: null,
      technicalEquipmentConsDatasetsForChart: null,
      evConsDatasetsForChart: null,
      solarProdDatasetsForChart: null,
      villaImportDatasetsForChart: null,
      villaExportDatasetsForChart: null,

      timeSpanStart: DateTime.local().setZone(this.isoTimezone).startOf('month').toMillis(),
      timeSpanEnd: DateTime.local().setZone(this.isoTimezone).startOf('day').toMillis()
    }
    
  }

  componentDidMount() {
    this.getDataFromApiForState();
  }

  componentDidUpdate(prevProps, prevState) {
    // Only go fetch new data if the home ID, timespan or granularity has changed
    if (
      this.state.timeSpanStart !== prevState.timeSpanStart || 
      this.state.timeSpanEnd !== prevState.timeSpanEnd || 
      this.state.currentlySelectedHomeId !== prevState.currentlySelectedHomeId ||
      this.state.timeGranularity !== prevState.timeGranularity
    ) {
      this.clearAllChartDatasets();
      this.getDataFromApiForState();
    }
  }

  componentWillUnmount() {
    // Kill the web worker before we leave
    this.HomesViewWorker.terminate();
  }

  getDataFromApiForState = () => {

    // const timespanPadding = 60000 * 6;
    const timespanPadding = 15 * 24 * 60 * 60 * 1000;

    const defaultGetDatasetsForChartWorkerData = {
      command: 'getDatasetsForChart',
      ref: '',
      data: [],
      start_time: this.state.timeSpanStart,
      end_time: this.state.timeSpanEnd,
      period: 60000,  // Period of the data used for normalization
      // max_gap_span: 60000 * 240,
      max_gap_span: 30 * 24 * 60 * 60 * 1000,  // 30 days of interpolation!!
      ticks: this.state.timeGranularity,
      multiplier: 1,
      trim_peaks: true,
      trim_peaks_max_delta_value: 10000,
      timezone: this.isoTimezone,
      tou_schedules: TimeOfUseSchedules,
      calculate_tou: true
    };

    fetchHesaHomeFiguresData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      from: this.state.timeSpanStart,
      to: this.state.timeSpanEnd
    }).then(res => {
      if (res.home_id === this.state.currentlySelectedHomeId) {
        this.setState({ homeData: res.data })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_lights_total_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'lights_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_large_appliances_total_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'large_appliances_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_heat_pump_hot_water_total_el_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'heat_and_hot_water_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_ventilation_total_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'ventilation_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_outlets_and_blinds_total_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'outlets_and_blinds_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_technical_equipment_total_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'technical_equipment_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_ev_total_cons_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'ev_cons',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_solar_total_energy_prod_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'solar_prod',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_villa_energy_import_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'villa_import',
          data: res.data,
          multiplier: -1
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

    fetchHesaHomeData({
      api_access_token: this.props.hesa.access_token,
      home_id: this.state.currentlySelectedHomeId,
      address: 'HESA_villa_energy_export_Wh',
      from: this.state.timeSpanStart - timespanPadding,
      to: this.state.timeSpanEnd + timespanPadding
    }).then(res => {
      if (parseInt(res.home_id) === this.state.currentlySelectedHomeId) {
        this.HomesViewWorker.postMessage({
          ...defaultGetDatasetsForChartWorkerData,
          ref: 'villa_export',
          data: res.data
        })
      } else { console.log("Ooops! API response is not for the currently selected home... Data is ignored") }
    }).catch(err => console.log(err));

  }

  handleMessageFromWorker = (event) => {

    const data = event.data.data || null;
    const meta = event.data.meta || null;

    console.log(event.data);

    switch (event.data.ref) {
      case 'large_appliances_cons':
        this.setState({ largeAppliancesConsDatasetsForChart: {data, meta} });
        break;
      case 'heat_and_hot_water_cons':
        this.setState({ heatHotWaterConsDatasetsForChart: {data, meta} });
        break;
      case 'ventilation_cons':
        this.setState({ ventilationConsDatasetsForChart: {data, meta} });
        break;
      case 'outlets_and_blinds_cons':
        this.setState({ outletsAndBlindsConsDatasetsForChart: {data, meta} });
        break;
      case 'lights_cons':
        this.setState({ lightsConsDatasetsForChart: {data, meta} });
        break;
      case 'technical_equipment_cons':
        this.setState({ technicalEquipmentConsDatasetsForChart: {data, meta} });
        break;
      case 'ev_cons':
        this.setState({ evConsDatasetsForChart: {data, meta} });
        break;
      case 'solar_prod':
        this.setState({ solarProdDatasetsForChart: {data, meta} });
        break;
      case 'villa_import':
        this.setState({ villaImportDatasetsForChart: {data, meta} });
        break;
      case 'villa_export':
        this.setState({ villaExportDatasetsForChart: {data, meta} });
        break;
      default:
        break;
    }
  }

  clearAllChartDatasets() {
    this.setState({
      homeData: {},
      largeAppliancesConsDatasetsForChart: null,
      heatHotWaterConsDatasetsForChart: null,
      ventilationConsDatasetsForChart: null,
      outletsAndBlindsConsDatasetsForChart: null,
      lightsConsDatasetsForChart: null,
      technicalEquipmentConsDatasetsForChart: null,
      evConsDatasetsForChart: null,
      solarProdDatasetsForChart: null,
      villaImportDatasetsForChart: null,
      villaExportDatasetsForChart: null,
    });
  }

  handleHomeIdChange = (e, { value }) => {
    this.clearAllChartDatasets();
    this.setState({
      currentlySelectedHomeId: value
    });
  }

  handleTimeSpanChange = (e, { name }) => {
    this.setState({
      timeSpan: name,
      timeSpanStart: this.getTimestampsFromTimeSpan(name).timespan_start,
      timeSpanEnd: this.getTimestampsFromTimeSpan(name).timespan_end,
    });
  }

  handleFilterChange = (filter) => {

    const timeSpanStart = DateTime.fromMillis(filter.fromDate.valueOf()).setZone(this.isoTimezone).startOf('day').toMillis();
    const timeSpanEnd = DateTime.fromMillis(filter.toDate.valueOf()).setZone(this.isoTimezone).startOf('day').toMillis();

    this.setState({
      currentlySelectedHomeId: filter.homeId,
      timeSpanStart,
      timeSpanEnd,
      timeGranularity: filter.timeGranularity
    })
    
  }


  render() {

    const homeData = this.state.homeData;

    let consumptionData = {};
    let importExportData = {};

    const largeAppliancesCons = this.state.largeAppliancesConsDatasetsForChart ? Math.round(this.state.largeAppliancesConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;
    const heatHotWaterCons = this.state.heatHotWaterConsDatasetsForChart ? Math.round(this.state.heatHotWaterConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;
    const ventilationCons = this.state.ventilationConsDatasetsForChart ? Math.round(this.state.ventilationConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;
    const outletsAndBlindsCons = this.state.outletsAndBlindsConsDatasetsForChart ? Math.round(this.state.outletsAndBlindsConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;
    const lightsCons = this.state.lightsConsDatasetsForChart ? Math.round(this.state.lightsConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;
    const technicalEquipmentCons = this.state.technicalEquipmentConsDatasetsForChart ? Math.round(this.state.technicalEquipmentConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;
    const evCons = this.state.evConsDatasetsForChart ? Math.round(this.state.evConsDatasetsForChart.meta.time_of_use.total) / 1000 : 0;

    try {
      consumptionData = {
        data: [
          lightsCons,
          largeAppliancesCons,
          ventilationCons,
          outletsAndBlindsCons,
          technicalEquipmentCons,
          heatHotWaterCons,
          evCons
        ],
        labels: [
          "Lights",
          "Large Appliances",
          "Ventilation",
          "Outlets & Blinds",
          "Technical Equipment",
          "Heat & Hot Water",
          "Electric Vehicle"
        ]
      };

      importExportData = {
        data: [
          Math.round(homeData.import_from_microgrid),
          Math.round(homeData.export_to_microgrid),
          Math.round(homeData.import_from_utility),
          Math.round(homeData.export_to_utility),
          Math.round(homeData.export_to_ce),
          Math.round(homeData.ce_share_utility),
        ],
        labels: [
          "MG Im.",
          "MG Ex.",
          "Util Im.",
          "Util Ex.",
          "Ex. to CE",
          "CE Util Share",
        ]
      };

    } catch(err) {
      // console.error(err);
    }

    return(
      <MainContentWrapper>
        <h2><Icon name='chevron right'/>Household Statistics</h2>
        <HomesViewActionBar 
          homeList={this.state.homeIds}
          currentHomeId={this.state.currentlySelectedHomeId}

          timeGranularityList={TimeGranularities}
          currentTimeGranularity={this.state.timeGranularity}
          
          currentFromDate={this.state.timeSpanStart}
          currentToDate={this.state.timeSpanEnd}
          timezone={this.isoTimezone}

          onFilterApply={this.handleFilterChange}
        />
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2rem'}}>
          <h2 style={{ color: primaryColor, margin: 0 }}>Basic Energy Insights</h2>
          <div style={{marginRight: '1rem'}}>
            <strong>{DateTime.fromMillis(this.state.timeSpanStart).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
            <span style={{margin: '0 0.5rem'}}><Icon name={'long arrow alternate right'} size={'large'}/></span>
            <strong>{DateTime.fromMillis(this.state.timeSpanEnd).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
          </div>
        </div>
        <SectionLayout>
          <ChartTile title={'Energy Consumption By Type'} width={48} minWidth={38} minHeight={'25rem'}>
            <DoughnutChart
              unit={'kWh'}
              dataSets={consumptionData.data}
              labels={consumptionData.labels}
              showDatasetTotal
            />
          </ChartTile>
          <ChartTile title={'Energy Import & Export'} width={48} minWidth={38} minHeight={'25rem'}>
            <DiscreteBarChart 
              unit={'kWh'}
              dataSets={importExportData.data ? importExportData.data : []}
              labels={importExportData.labels ? importExportData.labels : []}
              yAxisLabel={'Energy (kWh)'}
            />
          </ChartTile>
        </SectionLayout>
        <SectionSeparator/>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2rem'}}>
          <h2 style={{ color: primaryColor, margin: 0 }}>Energy Production</h2>
          <div style={{marginRight: '1rem'}}>
            <strong>{DateTime.fromMillis(this.state.timeSpanStart).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
            <span style={{margin: '0 0.5rem'}}><Icon name={'long arrow alternate right'} size={'large'}/></span>
            <strong>{DateTime.fromMillis(this.state.timeSpanEnd).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
          </div>
        </div>
        <SectionLayout>
          <ChartTile title={'Solar Production'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Production',
                  data: this.state.solarProdDatasetsForChart ? this.state.solarProdDatasetsForChart.data : [],
                  color: 'rgba(255, 205, 86, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Production',
                  items: [
                    { name: 'Total', value: this.state.solarProdDatasetsForChart ? Math.round(this.state.solarProdDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.solarProdDatasetsForChart ? Math.round(this.state.solarProdDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.solarProdDatasetsForChart ? Math.round(this.state.solarProdDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
        </SectionLayout>
        <SectionSeparator/>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2rem'}}>
          <h2 style={{ color: primaryColor, margin: 0 }}>Energy Consumption</h2>
          <div style={{marginRight: '1rem'}}>
            <strong>{DateTime.fromMillis(this.state.timeSpanStart).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
            <span style={{margin: '0 0.5rem'}}><Icon name={'long arrow alternate right'} size={'large'}/></span>
            <strong>{DateTime.fromMillis(this.state.timeSpanEnd).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
          </div>
        </div>
        <SectionLayout>
          <ChartTile title={'Heat & Hot Water'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.heatHotWaterConsDatasetsForChart ? this.state.heatHotWaterConsDatasetsForChart.data : [],
                  color: chartColors.purple,
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.heatHotWaterConsDatasetsForChart ? Math.round(this.state.heatHotWaterConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Ventilation'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.ventilationConsDatasetsForChart ? this.state.ventilationConsDatasetsForChart.data : [],
                  color: chartColors.blue,
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.ventilationConsDatasetsForChart ? Math.round(this.state.ventilationConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Outlets & Blinds'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.outletsAndBlindsConsDatasetsForChart ? this.state.outletsAndBlindsConsDatasetsForChart.data : [],
                  color: chartColors.orange,
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.outletsAndBlindsConsDatasetsForChart ? Math.round(this.state.outletsAndBlindsConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Large Appliances'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.largeAppliancesConsDatasetsForChart ? this.state.largeAppliancesConsDatasetsForChart.data : [],
                  color: chartColors.yellow,
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.largeAppliancesConsDatasetsForChart ? Math.round(this.state.largeAppliancesConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Technical Equipment'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.technicalEquipmentConsDatasetsForChart ? this.state.technicalEquipmentConsDatasetsForChart.data : [],
                  color: chartColors.green,
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.technicalEquipmentConsDatasetsForChart ? Math.round(this.state.technicalEquipmentConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Lights'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.lightsConsDatasetsForChart ? this.state.lightsConsDatasetsForChart.data : [],
                  color: chartColors.red,
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.lightsConsDatasetsForChart ? Math.round(this.state.lightsConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
          <ChartTile title={'Electric Vehicle'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              dataSets={[
                {
                  label: 'Consumption',
                  data: this.state.evConsDatasetsForChart ? this.state.evConsDatasetsForChart.data : [],
                  color: 'rgb(201, 203, 207)',
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Consumption',
                  items: [
                    { name: 'Total', value: this.state.evConsDatasetsForChart ? Math.round(this.state.evConsDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.evConsDatasetsForChart ? Math.round(this.state.evConsDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.evConsDatasetsForChart ? Math.round(this.state.evConsDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
        </SectionLayout>
        <SectionSeparator/>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '2rem'}}>
          <h2 style={{ color: primaryColor, margin: 0 }}>Energy Import & Export</h2>
          <div style={{marginRight: '1rem'}}>
            <strong>{DateTime.fromMillis(this.state.timeSpanStart).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
            <span style={{margin: '0 0.5rem'}}><Icon name={'long arrow alternate right'} size={'large'}/></span>
            <strong>{DateTime.fromMillis(this.state.timeSpanEnd).setZone(this.isoTimezone).toLocaleString(DateTime.DATETIME_MED)}</strong>
          </div>
        </div>
        <SectionLayout>
          <ChartTile title={'Villa Import/Export'} width={100} minWidth={38}>
            <TimeseriesBarChart
              legend
              stacked
              dataSets={[
                {
                  label: 'Import',
                  data: this.state.villaImportDatasetsForChart ? this.state.villaImportDatasetsForChart.data : [],
                  color: 'rgba(245, 120, 150, 1)',
                },
                {
                  label: 'Export',
                  data: this.state.villaExportDatasetsForChart ? this.state.villaExportDatasetsForChart.data : [],
                  color: 'rgba(70, 165, 225, 1)',
                }
              ]}
              timezone={this.isoTimezone}
              yAxisLabel={'Energy (kWh)'}
              timeScale={'day'}
            />
            <ChartStats
              numberFormat={this.numberFormat}
              sections={[
                {
                  title: 'Energy Import',
                  items: [
                    { name: 'Total', value: this.state.villaImportDatasetsForChart ? Math.round(this.state.villaImportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villaImportDatasetsForChart ? Math.round(this.state.villaImportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villaImportDatasetsForChart ? Math.round(this.state.villaImportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                },
                {
                  title: 'Energy Export',
                  items: [
                    { name: 'Total', value: this.state.villaExportDatasetsForChart ? Math.round(this.state.villaExportDatasetsForChart.meta.time_of_use.total) / 1000 : '-', unit: 'kWh' },
                    { name: 'Soft Hours', value: this.state.villaExportDatasetsForChart ? Math.round(this.state.villaExportDatasetsForChart.meta.time_of_use.soft_hours) / 1000 : '-', unit: 'kWh' },
                    { name: 'Peak Hours', value: this.state.villaExportDatasetsForChart ? Math.round(this.state.villaExportDatasetsForChart.meta.time_of_use.peak_hours) / 1000 : '-', unit: 'kWh' }
                  ]
                }
              ]}
            />
          </ChartTile>
        </SectionLayout>
      </MainContentWrapper>
    )
  }
}

const SectionLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`

const SectionSeparator = styled.div`
  display: flex;
  padding: 1rem 0 2rem 0;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const mapStateToProps = (state) => ({
  user: state.auth.user,
  app: state.app,
  hesa: state.hesa
});

export default connect(mapStateToProps)(HomesView);