import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

import { Form, Button } from 'semantic-ui-react'

import { setAuthUser, setHesaApiAccessToken } from 'actions';

import { requestHesaApiAccessToken } from 'api/HesaApi';

import { themeColors } from 'Theme';

const {
  primaryColor,
  labelTextColor
} = themeColors;


class HesaApiSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hesa_api_client_id: this.props.settings.hesa_api_client_id || '',
      hesa_api_client_secret: this.props.settings.hesa_api_client_secret || '',
      form_submit_is_disabled: true
    }
  }

  saveNewSettings = async () => {

    // Get the currently authenticated user
    let user = await Auth.currentAuthenticatedUser();

    // Save the client_id and secret as cognito user attributes
    let result = await Auth.updateUserAttributes(user, {
      'custom:hesa_api_client_id': this.state.hesa_api_client_id,
      'custom:hesa_api_secret': this.state.hesa_api_client_secret
    });

    // If we are successful,
    if (result === 'SUCCESS') {

      // We get request the cognito user again with by also bypassing the cache
      await Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(cognitoUser => {
          this.props.setAuthUser(cognitoUser.attributes);  // Store the attributes in the Redux store
        }).catch(err => console.log(err));

      // Request the HESA API token based on the new user attributes
      await requestHesaApiAccessToken({ client_id: this.state.hesa_api_client_id, client_secret: this.state.hesa_api_client_secret })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.props.setHesaApiAccessToken(res.data.access_token);
          }
        }).catch((err) => {
          console.log(err);
          this.props.setHesaApiAccessToken(null);
        });

      this.setState({
        form_submit_is_disabled: true
      });

    }
  }

  handleFieldValueChange = (event, data) => {
    this.setState({
      [data.name]: data.value,
      form_submit_is_disabled: !data.value
    });
  }

  handleSaveButtonClick = (event) => {
    this.saveNewSettings();
  }

  render() {
    return(
      <Form onSubmit={this.handleSubmitButtonClick}>
        <h3 style={{color: primaryColor}}>HESA API Credentials</h3>
        <Form.Group widths={12} style={{marginTop: '2rem'}}>
          <Form.Field 
            width={2}
            control={Form.Input}
            label='Client ID'
            placeholder='ID'
            value={this.state.hesa_api_client_id}
            name={'hesa_api_client_id'}
            onChange={this.handleFieldValueChange}
            required
            error={!this.state.hesa_api_client_id || !this.props.hesa.access_token}
          />
          <Form.Field
            width={10}
            control={Form.Input}
            label='Client Secret'
            placeholder='Secret'
            value={this.state.hesa_api_client_secret}
            name={'hesa_api_client_secret'}
            onChange={this.handleFieldValueChange}
            required
            error={!this.state.hesa_api_client_secret || !this.props.hesa.access_token}
          />
        </Form.Group>
        <div style={{color: labelTextColor}}>
          <span>These credentials are issued by the application's admin. Please contact mike@bitfuel.io if you did not receive yours.</span>
        </div>
        <Button
          onClick={this.handleSaveButtonClick}
          content={'Save & Verify Settings'}
          style={{marginTop: '1.5rem'}}
          color='blue'
          disabled={this.state.form_submit_is_disabled}
        />
      </Form>
    )
  }

}

const mapStateToProps = (state) => ({
  hesa: state.hesa
});

export default connect(mapStateToProps, { setAuthUser, setHesaApiAccessToken })(HesaApiSettings);