// React and Redux imports
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

// Redux Reducers
import Reducers from 'combinedReducers';

// User Components
import AppContainer from 'AppContainer';

Amplify.configure(awsconfig);

// Create store for app state with Redux
const store = createStore(        // function 'createStore' takes:
  Reducers,                       // 1 - our Reducers
  {},                             // 2 - a default store object [opt]
  composeWithDevTools(            // 3 - a list of middlewares with DevTools
    applyMiddleware(ReduxThunk)   //     (here we want to use ReduxThunk) [opt]
  )
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <AppContainer />
      </Provider>
    );
  }
}

export default App;