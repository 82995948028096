import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { Auth } from 'aws-amplify';

import { setAuthState, setAuthUser, setAppLoadingStateTo, setHesaApiAccessToken } from 'actions';

class LogoutLink extends Component {

  handleLogoutClick = () => {
    Auth.signOut()
      .then(() => { 
          this.props.setAuthState("signIn");
          this.props.setAuthUser(null);
          this.props.setAppLoadingStateTo(false);
          this.props.setHesaApiAccessToken(null);
        }
      ).catch(err => console.log(err));
  } 

  render() {
    return (
      <Link to="/" onClick={this.handleLogoutClick} style={this.props.style}>{this.props.children}</Link>
    );
  }
}

export default connect(null, { setAuthState, setAuthUser, setAppLoadingStateTo, setHesaApiAccessToken })(LogoutLink);