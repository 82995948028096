import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { chartColors } from 'Theme';

class BarChart extends Component {

  render() {
    return(
      <Bar 
        height={this.props.height}
        data={{
          labels: this.props.labels,
          datasets: [{
            data: this.props.dataSets,
            backgroundColor: [
              chartColors.blue,
              chartColors.yellow,
              chartColors.red,
              chartColors.purple,
              chartColors.green,
              chartColors.orange,
              chartColors.grey
            ]
          }]
        }}
        options={{
          responsive: true,
          legend: {
            display: false
          },
          layout: {
              padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 0
            }
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                let label = data.labels[tooltipItem.index] || '';
                if (label) {
                  label += ': ';
                }
                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                label += Math.round(value * 10) / 10 + ' ' + this.props.unit;
                return label;
              }
            }
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.props.yAxisLabel ? this.props.yAxisLabel : '',
                fontStyle: 'bold'
              }
            }]
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                return (value + ' ' + this.props.unit);
              },
              color: '#36A2EB',
              font: {
                weight: 'bold',
              },
              anchor: 'end',
              align: 'end',
              offset: 1,
              backgroundColor: 'rgba(255,255,255,1)',
              display: 'auto'
            }
          }
        }}
        plugins={ chartPlugins }
      />
    )
  }
  
}

const chartPlugins = [ChartDataLabels];

export default BarChart;