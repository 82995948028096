const TimeOfUseSchedules = [
  {
    name: 'soft_hours',
    schedule: {
      monday:    [{from: '00:00:00', to: '07:00:00'}, {from: '22:00:00', to: '24:00:00'}],
      tuesday:   [{from: '00:00:00', to: '07:00:00'}, {from: '22:00:00', to: '24:00:00'}],
      wednesday: [{from: '00:00:00', to: '07:00:00'}, {from: '22:00:00', to: '24:00:00'}],
      thursday:  [{from: '00:00:00', to: '07:00:00'}, {from: '22:00:00', to: '24:00:00'}],
      friday:    [{from: '00:00:00', to: '07:00:00'}, {from: '22:00:00', to: '24:00:00'}],
      saturday:  [{from: '00:00:00', to: '17:00:00'}, {from: '22:00:00', to: '24:00:00'}],
      sunday:    [{from: '00:00:00', to: '17:00:00'}, {from: '22:00:00', to: '24:00:00'}]
    }
  },
  {
    name: 'peak_hours',
    schedule: {
      monday:    [{from: '07:00:00', to: '22:00:00'}],
      tuesday:   [{from: '07:00:00', to: '22:00:00'}],
      wednesday: [{from: '07:00:00', to: '22:00:00'}],
      thursday:  [{from: '07:00:00', to: '22:00:00'}],
      friday:    [{from: '07:00:00', to: '22:00:00'}],
      saturday:  [{from: '17:00:00', to: '22:00:00'}],
      sunday:    [{from: '17:00:00', to: '22:00:00'}]
    }
  }
]

export default TimeOfUseSchedules;